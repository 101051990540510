import Headroom from "headroom.js";

var options = {
    offset: 100,
}
if (Headroom.cutsTheMustard) {
    const header = document.querySelector("header");
    const headroom = new Headroom(header, options);
    headroom.init();
}
