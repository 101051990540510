"use strict";

import mixitup from 'mixitup';
import mixitupMultifilter from '../optional/mixitup-multifilter.min.js';
import mixitupPagination from '../optional/mixitup-pagination.min.js';

mixitup.use(mixitupMultifilter);
mixitup.use(mixitupPagination);

jQuery(document).ready(function ($) {

    if ($('.tiles--resources').length) {

        let selectFilter = document.querySelector('.resource-filter');
        let container = document.querySelector('.tiles--resources');

        let args = {
            selectors: {
                target: '.mix'
            },
            animation: {
                duration: 500
            },
            multifilter: {
                enable: true
            },
            pagination: {
                limit: 9,
                hidePageListIfSinglePage: true,
            },
        };
        let mixer = mixitup(container, args);

        selectFilter.addEventListener('change', function () {
            let selector = selectFilter.value;
            mixer.filter(selector);
        });

        if (location.hash) {
            var hash = location.hash.replace('#', '.');
            var selectedVal = $('.select-filter').find("[value='" + hash + "']");
            selectedVal.prop("selected", "selected");
            mixer.filter(hash)
        }
    }

    if ($('.tiles--customer-stories-pagination').length) {

        let container = document.querySelector('.tiles--customer-stories-pagination');

        let args = {
            selectors: {
                target: '.mix'
            },
            animation: {
                duration: 500
            },
            multifilter: {
                enable: true
            },
            pagination: {
                limit: 15,
                hidePageListIfSinglePage: false,
            },
        };
        let mixer = mixitup(container, args);
    }
});