import Swiper from 'swiper/dist/js/swiper.min';

let homeSlider = document.getElementById('home-slider');
let allHomeSliders = 0;
if (homeSlider !== null && homeSlider !== undefined) {
    allHomeSliders = homeSlider.getElementsByClassName('swiper-slide');
}

if (allHomeSliders.length > 1) {
    const mySlider = new Swiper('.home-slider__swiper', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });
}

var mySwiper = new Swiper('.slider__container', {
    effect: 'coverflow',
    slidesPerView: 'auto',
    loop: false,
    navigation: {
        nextEl: '.slider__control--next',
        prevEl: '.slider__control--prev',
    },
})

var mySwiper = new Swiper('.logo-slider', {
    slidesPerView: 5,
    loop: true,
    loopedSlides: null,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    breakpoints: {
        768: {
            slidesPerView: 4,
        },
        767: {
            slidesPerView: 2,
        }
    }

});

var mySwiper = new Swiper('.testimonials-slider', {
    slidesPerView: 2,
    loop: false,
    loopedSlides: null,
    breakpoints: {
        767: {
            slidesPerView: 1
        }
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
});

const resourcesSlider = new Swiper('.resources-slider', {
    slidesPerView: 3,
    spaceBetween: 30,
    loop: false,
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    },
    breakpoints: {
        1024: {
            slidesPerView: 2.4,
        },
        768: {
            slidesPerView: 2,
        },
        767: {
            slidesPerView: 1.6,
        },
        500: {
            slidesPerView: 1.2,
        }
    }
})

if (jQuery(window).width() > 767) {
    var pageTopContent = new Swiper('.page-top-content', {
        speed: 600,
        slidesPerView: 1,
        loopedSlides: null,
        effect: 'fade',
        autoplay: {
            delay: 5000,
        },
        fadeEffect: {
            crossFade: false,
        },
        navigation: {
            nextEl: '.page-top__control--next',
            prevEl: '.page-top__control--prev',
        },
        loop: true,
    });
}

if (jQuery(window).width() > 767) {
    var pageTopImage = new Swiper('.page-top__image--slider', {
        speed: 600,
        slidesPerView: 1,
        loopedSlides: null,
        effect: 'fade',
        autoplay: {
            delay: 5000,
        },
        fadeEffect: {
            crossFade: false,
        },
        navigation: {
            nextEl: '.page-top__control--next',
            prevEl: '.page-top__control--prev',
        },
        loop: true,
    });

}
