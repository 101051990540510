import Cookies from './js-cookie';

customElements.define(
        "sticky-ribbon",
        class extends HTMLElement {
            constructor() {
                super();

                this.attachShadow({mode: "open"});

                const
                        fragment = document.createRange().createContextualFragment(`
<div class="root">
    <div class="wrapper">
        <a class="close">
            X
        </a>
        <div class="content">
            ${this.innerHTML}
            <a class="cta"></a>
        </div>
    </div>
</div>
`),
                        style = document.createElement("style")
                ;

                style.textContent = `
.root {
    background: ${this.getAttribute('data-background-color')};
    color: ${this.getAttribute('data-text-color')};
    position: relative;
    width: 100%;
}

.wrapper {
    padding: .4rem 0 .6rem 1.8rem;
}

.content {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;
}

a {
    line-height: 1;
    text-decoration: none;
}

p {
    display: inline;
    margin: 0;
    padding: 0;
}

.cta {
    background: ${this.getAttribute('data-cta-background-color')};
    border-radius: .3rem;
    color: ${this.getAttribute('data-cta-text-color')};
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 600;
    margin: .2rem .9rem 0;
    padding: .7rem 1.2rem;
}

.close:hover,
.cta:hover {
    opacity: 0.9;
}

.close {
    color: ${this.getAttribute('data-close-icon-color')};
    cursor: pointer;
    display: block;
    float: right;
    font-size: 20px;
    font-weight: 400;
    margin: .6rem 0;
    width: 40px;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .content {
        font-size: 15px;
    }

    .cta {
        font-size: 14px;
    }

    .close {
        font-size: 20px;
    }
}
`;
                this.shadowRoot.appendChild(style);
                this.shadowRoot.appendChild(fragment.cloneNode(true));
                this.shadowRoot.querySelector('.cta').setAttribute('href', this.getAttribute('data-cta-link'));
                this.shadowRoot.querySelector('.cta').innerHTML = this.getAttribute('data-cta-text');
            }

            connectedCallback() {
                this.shadowRoot.querySelector('.close').addEventListener("click", e => {
                    jQuery(this.shadowRoot.querySelector('.root')).hide();
                    Cookies.set('ribbon-close-' + this.getAttribute('data-id'), true, { expires: 7 });
                    jQuery(window).trigger('resize');
                });

                jQuery(window).trigger('resize');
            }
        }
);

jQuery(async $ => {
    $( document ).ready(async() => {
        if (!window.site_url)
            return
        ;

        const
            response = await fetch(window.site_url + "/wp-json/wp/v2/ribbons"),
            ribbons = await response.json()
        ;

        if (!ribbons.length)
            return
        ;

        const ribbonResult = [];
        for (let k in ribbons) {
            if (!Cookies.get('ribbon-close-' + ribbons[k].id))
                ribbonResult.push(ribbons[k]);
        }

        if (!ribbonResult.length)
            return
        ;

        let ribbon;
        for (let k in ribbonResult)
            if (!Cookies.get('ribbon-view-' + ribbonResult[k].id))
                ribbon = ribbonResult[k]
        ;

        if (!ribbon) {
            for (let k in ribbons)
                Cookies.remove('ribbon-view-' + ribbons[k].id)
            ;

            ribbon = ribbons.pop();
        }

        Cookies.set('ribbon-view-' + ribbon.id, true, { expires: 7 });

        const el = $(`
<sticky-ribbon is="sticky-ribbon"
     class="sticky-ribbon"
     data-id="${ribbon.id}"
     data-background-color="${ribbon.acf.background_color}"
     data-close-icon-color="${ribbon.acf.close_icon_color}"
     data-cta-background-color="${ribbon.acf.cta_background_color}"
     data-cta-link="${ribbon.acf.cta_link.url}"
     data-cta-text="${ribbon.acf.cta_text}"
     data-text-color="${ribbon.acf.text_color}"
     data-cta-text-color="${ribbon.acf.cta_text_color}">${ribbon.content.rendered}</sticky-ribbon>
`);
        $('header.header').prepend(el);
    });
});
