/*******************
 Make sure the page-header is below the fixed header
 * *******************/
var positionPageHeaderBelowFixedHeaderTimer;
function positionPageHeaderBelowFixedHeader() {
    if (positionPageHeaderBelowFixedHeaderTimer)
        clearTimeout(positionPageHeaderBelowFixedHeaderTimer)
    ;

    positionPageHeaderBelowFixedHeaderTimer = setTimeout(() => {
        const $header = jQuery('header.header');
        $header.next().css('margin-top', $header.height() + 'px');
    }, 100);
}
positionPageHeaderBelowFixedHeader();
jQuery(window).resize(positionPageHeaderBelowFixedHeader);

/*******************
 Anchor links
 *******************/
jQuery('a[href*="#"]:not([href="#"])').click(function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            || location.hostname == this.hostname) {

        var target = jQuery(this.hash);
        target = target.length ? target : jQuery('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
            jQuery('html,body').animate({
                scrollTop: target.offset().top - 100
            }, 500);
            return false;
        }
    }
});

/*******************
 Toggle subnav background
 *******************/
jQuery('.page-navigation>ul>.menu-item-has-children').hover(function () {
    jQuery('body').addClass('has-dark-overlay');
}, function () {
    jQuery('body').removeClass('has-dark-overlay');
});

/*******************
 Customer tabs
 *******************/
jQuery('.customer-tabs__tabs--tabs .tab').on('click', function () {
    var dataId = jQuery(this).data('id');
    var matchingContent = jQuery('.customer-tabs__content').find('.content[data-id=' + dataId + ']');

    jQuery('.tab').removeClass('tab--active');
    jQuery(this).addClass('tab--active');

    jQuery('.customer-tabs__content .content').removeClass('content--active');
    jQuery(matchingContent).addClass('content--active');
});

/*******************
 Tabs
 *******************/
jQuery('.tabs__titles li').on('click', function () {
    var dataId = jQuery(this).data('id');
    var matchingContent = jQuery('.tabs__content[data-id=' + dataId + ']');

    jQuery('.tabs__titles li').removeClass('active');
    jQuery(this).addClass('active');

    jQuery('.tabs__content').removeClass('tabs__content--active');
    jQuery(matchingContent).addClass('tabs__content--active');
});

/*******************
 Search bar
 *******************/
jQuery('.search-bar-toggle').on('click', function () {
    jQuery(this).addClass('search-bar-toggle--hide');
    jQuery('.header__search').toggleClass('header__search--active');

    setTimeout(function () {
        jQuery('.search-form__field').focus();
    }, 100);
});

jQuery('.search-form__close').on('click', function () {
    jQuery('.search-bar-toggle').removeClass('search-bar-toggle--hide');
    jQuery('.header__search').removeClass('header__search--active');
});

/*******************
 Toggle mobile subnav
 *******************/
jQuery('.mobile-toggle').on('click', function () {
    jQuery('.header__mobile-wrapper').toggleClass('header__mobile-wrapper--active');
    jQuery('.hamburger').toggleClass('is-active');
});

jQuery('.sub-menu-toggle').on('click', function () {
    var Parent = jQuery(this).parent();
    var matchingUl = jQuery(Parent).find('.sub-menu-first');

    jQuery(this).toggleClass('sub-menu-toggle--active');
    jQuery(matchingUl).stop().slideToggle();
});

/*******************
 Share this
 *******************/


jQuery('#timezones').on('change', function () {
    let timezone = jQuery(this).val();
    //console.log( timezone );

    // loop over timetable
    jQuery('[data-convertible-time]').each(function (i, el) {
        let $el = jQuery(el);
        let date = $el.data('convertible-time');
        //console.log($el, date);
        let newDate = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: timezone}));

        let newHours = newDate.getHours();
        if (newHours < 10) newHours = '0' + newHours;
        let newMinutes = newDate.getMinutes();
        if (newMinutes < 10) newMinutes = newMinutes + '0';

        $el.find('span').text(newHours + ':' + newMinutes);
    });
});


/*******************
 Open Modal for Timetrackings
 *******************/
function openTimetrackingModal(timetracking) {
    let modal = jQuery('#timetable__modal');

    let title = modal.find('.wrapper > h2');
    let speaker = modal.find('.wrapper > p');
    let description = modal.find('.wrapper > div');

    let modalTitle = timetracking.find('span').text().trim();
    let modalSpeaker = timetracking.parents('.timetable__row').data('speaker').trim();
    let modalDescription = timetracking.parents('.timetable__row').data('description').trim();

    title.text(modalTitle);
    speaker.text('Presented by ' + modalSpeaker);
    description.html(modalDescription);

    modal.addClass('active');
    jQuery('body, html').css('overflow', 'hidden');
}

jQuery(document).on('click', '.timetable__cell-programme', function (event) {
    event.preventDefault();

    openTimetrackingModal(jQuery(this));
});


function closeTimetrackingModal() {
    let modal = jQuery('#timetable__modal');

    modal.removeClass('active');
    jQuery('body, html').css('overflow', 'visible');
}

jQuery(document).on('click', '.timetable__modal-inner > span, .timetable__modal__bg', function (event) {
    event.preventDefault();

    closeTimetrackingModal();
});

/*******************
 Share this
 *******************/
jQuery('.share-this__toggle').hover(
        function () {
            jQuery(this).parent().addClass('share-this--show');
        },
        function () {
            jQuery(this).parent().removeClass('share-this--show');
        }
)

jQuery('.share-this__list li').hover(
        function () {
            jQuery(this).parent().parent().addClass('share-this--show');
        },
        function () {
            jQuery(this).parent().parent().removeClass('share-this--show');
        }
)

jQuery('#timezones').on('change', function () {
    let timezone = jQuery(this).val();

    // loop over timetable
    jQuery('[data-convertible-time]').each(function (i, el) {
        let $el = jQuery(el);
        let date = $el.data('convertible-time');
        let newDate = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: timezone}));
        let newHours = newDate.getHours();
        if (newHours < 10) newHours = '0' + newHours;
        let newMinutes = newDate.getMinutes();
        if (newMinutes < 10) newMinutes = newMinutes + '0';

        $el.find('span').text(newHours + ':' + newMinutes);
    });
});

/*******************
 Events overview load more button
 *******************/
jQuery(document).on('ready', function ($) {
    jQuery('.load-more-btn').on('click', function (e) {
        jQuery(this).toggleClass('is-loaded');
    });
});

/*******************
 Update card for employee
 *******************/
function updateEmployeeCard(speaker) {
    let infoBox = jQuery('.speaker__featured');
    let name = infoBox.find('.speaker__featured-name');
    let role = infoBox.find('.speaker__featured-function');
    let description = infoBox.find('.speaker__featured-description');
    let linkedin = infoBox.find('.button');

    let speakerName = speaker.find('.speaker__details-name').text().trim();
    let speakerRole = speaker.find('.speaker__details-function').text().trim();
    let speakerDesc = speaker.data('description').trim();
    let speakerLink = speaker.data('linkedin').trim();

    name.text(speakerName);
    role.text(speakerRole);
    description.text(speakerDesc);
    linkedin.attr('href', speakerLink);
}

jQuery(document).on('click, mouseover', '.speaker__detail', function (event) {
    event.preventDefault();

    jQuery('.speaker__detail').removeClass('active');
    jQuery(this).addClass('active');

    updateEmployeeCard(jQuery(this));
});

/*******************
 Events overview load more button
 *******************/
jQuery(document).on('ready', function ($) {
    jQuery('.load-more-btn').on('click', function (e) {
        jQuery('.loaded').toggleClass('is-loaded');
    });

    jQuery('.home-quicklinks__title').each(function () {
        var html = jQuery(this).html();
        var word = html.substr(0, html.indexOf(" "));
        var rest = html.substr(html.indexOf(" "));
        jQuery(this).html(rest).prepend(jQuery("<span/>").html(word).addClass("first-word"));
    });
});


/*===================================================
*           EVENTS HOSTING & PAST FILTERING         *
===================================================*/

document.addEventListener("DOMContentLoaded", function () {
    function eventsHosting() {
        let checkBox = document.querySelectorAll('.event-filter-checkbox-hosting');
        let card = document.querySelectorAll('.filter-event-hosting');
        let loadMore = document.querySelector('.load-more-btn');

        for (let i = 0; i < checkBox.length; i++) {
            checkBox[i].addEventListener('click', function () {
                if (loadMore) {
                    loadMore.classList.toggle('display-none');
                }

                for (let o = 0; o < card.length; o++) {
                    if (!card[o].classList.contains(checkBox[i].dataset.value)) {
                        card[o].classList.toggle('display-none');
                    }

                    if (card[o].classList.contains(checkBox[i].dataset.value)) {
                        card[o].classList.toggle('show-more');
                    }
                }
            });
        }
    }

    eventsHosting();

    function eventsPast() {
        let checkBox = document.querySelectorAll('.event-filter-checkbox-past');
        let card = document.querySelectorAll('.filter-event-past');
        let loadMore = document.querySelectorAll('.load-more-btn');

        for (let i = 0; i < checkBox.length; i++) {
            checkBox[i].addEventListener('click', function () {
                if (loadMore[1]) {
                    loadMore[1].classList.toggle('display-none');
                }


                for (let o = 0; o < card.length; o++) {
                    if (!card[o].classList.contains(checkBox[i].dataset.value)) {
                        card[o].classList.toggle('display-none');
                    }

                    if (card[o].classList.contains(checkBox[i].dataset.value)) {
                        card[o].classList.toggle('show-more');
                    }
                }
            });
        }
    }

    eventsPast();
});

window.addEventListener('CookiebotOnAccept', function () {
    document.getElementById('CybotCookiebotDialog').remove();
});

window.addEventListener('CookiebotOnDecline', function () {
    document.getElementById('CybotCookiebotDialog').remove();
});
