const animatedPages = document.querySelectorAll('.page-template-page-about,.home');

if (animatedPages) {

    const animatedHeader = document.querySelector('.animated-img--header');

    setTimeout(function () {
        animatedHeader.classList.add('active');
    }, 1000);

}

const targetElement = document.querySelector('.footer');

const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            targetElement.classList.add('in-view');
        } else {
            targetElement.classList.remove('in-view');
        }
    });
});

observer.observe(targetElement);
